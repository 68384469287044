import { createContext, useState } from "react";
import { BaseUrl } from "../API/ApiHandle";
import axios from "axios";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

export const ChartContext = createContext();

export default function Charts({ children }) {
  const [Chart, setChart] = useState("Pie");
  const [TableId, setTableId] = useState();
  const [Cookies] = useCookies();
  const [Confirmed, setConfirmed] = useState();
  const [profilePage, setprofilePage] = useState(1);

  const User = Cookies.rqm && jwtDecode(Cookies.rqm);

  const ChooseChart = (Value) => {
    setChart(Value);
  };

  const GetTableID = async () => {
    try {
      const { data } = await axios.get(`${BaseUrl}/main/user_files`, {
        headers: {
          Authorization: `Bearer ${Cookies.rqm}`,
        },
      });
      if (data.files_info.length) {
        setTableId(data.files_info[0]._id);
      } else {
        setTableId(false);
      }
    } catch (error) {
      console.error("Error fetching table ID:", error);
    }
  };

  return (
    <ChartContext.Provider
      value={{
        ChooseChart,
        Chart,
        TableId,
        setTableId,
        GetTableID,
        User,
        Confirmed,
        setConfirmed,
        profilePage,
        setprofilePage,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
}
