import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import Layout from './pages/Layout';
import { lazy, Suspense, useContext, useEffect } from 'react';
import Loading from './components/Loading/Loading';
import LoginProtector from './protector/LoginProtector';
import AuthLayout from './pages/Auth/AuthLayout';
import axios from 'axios';
import { BaseUrl } from './API/ApiHandle';
import { useCookies } from 'react-cookie';
import { ChartContext } from './Context/Charts';
import DataIdProtector from './protector/DataIdProtector';
import ShareLayout from './pages/Share/ShareLayout';
import ChangeEmailLayout from './pages/ChangeEmail.Layout';
import NotFound from './pages/NotFound/NotFound';
import AlertPage from './pages/AlertPage/AlertPage';

const Home = lazy(() => import("./pages/Home/Home"));
const SignUp = lazy(() => import("./pages/Auth/SignUp/SignUp"));
const Visualize = lazy(() => import("./pages/Visualize/Visualize"));
const Support = lazy(() => import("./pages/Support/Support"));
const Tables = lazy(() => import("./pages/Tables/Tables"));
const Share = lazy(() => import("./pages/Share/Share"));

const Login = lazy(() => import("./pages/Auth/Login/Login"));
const Forget = lazy(() => import("./pages/Auth/ForgetPassword/ForgetPassword"));
const Change = lazy(() => import("./pages/Auth/ChangePassword/ChangePassword"));
const ChangeEmail = lazy(() => import('./pages/Auth/ChangeEmail/ChangeEmail'))

const Profile = lazy(() => import("./pages/Profile/Profile"));


function App() {

  const [Cookies,] = useCookies(['rqm']);
  const { setTableId, TableId, setConfirmed } = useContext(ChartContext);

  useEffect(() => {
    const fetchUserConfirmation = async () => {
      if (!Cookies.rqm) return;

      try {
        const { data } = await axios.get(`${BaseUrl}/check_confirmation`, {
          headers: {
            Authorization: `Bearer ${Cookies.rqm}`
          }
        });
        console.log(data);

        setConfirmed(data?.confirmed)
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserConfirmation();
  }, [Cookies.rqm]);

  const router = createBrowserRouter([
    {
      path: "", element: <Layout />, children: [
        {
          index: true, element:
            <LoginProtector>
              <Suspense fallback={<Loading />}><Home /></Suspense>
            </LoginProtector>
        },

        {
          path: 'Tables/:id', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <DataIdProtector>
                <Tables />
              </DataIdProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Visualize', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <DataIdProtector>
                <Visualize />
              </DataIdProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Support', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Support />
            </LoginProtector>
          </Suspense>
        },
        {
          path: "profile", element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <Profile />
            </LoginProtector>
          </Suspense>
        }
      ],
    }, {
      path: "", element: <AuthLayout />, children: [
        { path: "Login", element: <Suspense fallback={<Loading />}><Login /></Suspense> },
        { path: "SignUp", element: <Suspense fallback={<Loading />}><SignUp /></Suspense> },
        { path: "Forget", element: <Suspense fallback={<Loading />}><Forget /></Suspense> },
        { path: "Reset/:rqm", element: <Suspense fallback={<Loading />}><Change /></Suspense> },
      ]
    }, {
      path: "", element: <ShareLayout />, children: [
        { path: "Charts/:file_id", element: <Suspense fallback={<Loading />}><Share /></Suspense> }
      ]
    }, {
      path: "changeEmail", element: <ChangeEmailLayout />, children: [
        {
          path: ":rqm", element: <Suspense fallback={<Loading />}>
            <ChangeEmail />
          </Suspense>
        }
      ]
    }, {
      path: "*", element: <NotFound />,
    }, {
      path: "Alert/:errorMessage/:userId", element: <AlertPage />
    }
  ])

  const GetTableID = async () => {
    try {
      const { data } = await axios.get(`${BaseUrl}/main/user_files`, {
        headers: {
          'Authorization': `Bearer ${Cookies.rqm}`
        }
      });
      if (data.files_info.length) {
        setTableId(data.files_info[0]._id);
      }
    } catch (error) {
      console.error('Error fetching table ID:', error);
    }
  };


  useEffect(() => {
    if (Cookies.rqm && !TableId) {
      GetTableID();
    }
  }, [Cookies.rqm, TableId]);

  console.clear();

  return <RouterProvider router={router}>
  </RouterProvider>
}

export default App;
