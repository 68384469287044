import React, { useEffect } from 'react'
import Logo from '../../Assets/Images/New_logo.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { BaseUrl } from '../../API/ApiHandle';

export default function AlertPage() {

    const { errorMessage, userId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const CheckUserId = async () => {

            if (!userId)
                return navigate("/");

            try {
                const { data } = await axios.get(`${BaseUrl}/check_user_id`, {
                    params: { userId }
                });

                console.log(data);


                if (data.status === "success")
                    return;

                if (data.error_field)
                    return navigate("/");

            } catch (error) {
                navigate("/")
            }
        };

        CheckUserId();
    }, [userId]);


    return <div className="bg-primary-500">
        <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">

            <div className="bg-white shadow overflow-hidden rounded-lg pb-8 lg:w-[800px] w-full">
                <div className="border-t border-gray-200 text-center pt-8">

                    <div className="w-full flex justify-center items-center">
                        <img src={Logo} alt="Rqm Logo" className='w-[150px]' />
                    </div>

                    <h1 className="lg:text-5xl text-2xl font-medium lg:py-6 py-4">oops! Problem Found</h1>

                    <p className="text-2xl pb-8 px-12 font-medium">{errorMessage}</p>

                    <div className="w-ful flex justify-center items-center">
                        <Link to={'/'} className="bg-gradient-to-r from-primary-400 to-primary-500 hover:from-primary-500 hover:to-primary-500
                         text-white font-semibold py-3 rounded-md w-1/3">
                            Home
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    </div>
}
